import React, { useState, useEffect } from 'react';
import { ExternalLink } from 'lucide-react';

const Portfolio = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const socialLinks = [
    {
      name: "Instagram",
      url: "https://www.instagram.com/akalintechs/",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
    },
    {
      name: "Facebook",
      url: "https://www.facebook.com/akalintech",
      icon: "https://static.vecteezy.com/system/resources/previews/023/986/999/non_2x/facebook-logo-facebook-logo-transparent-facebook-icon-transparent-free-free-png.png"
    },
    {
      name: "(x) Twitter",
      url: "https://x.com/AkalinTech",
      icon: "https://freepnglogo.com/images/all_img/1725374683twitter-x-logo.png"
    },
    {
      name: "Tiktok",
      url: "https://www.tiktok.com/@akalintech",
      icon: "https://static.vecteezy.com/system/resources/thumbnails/018/930/470/small_2x/tiktok-logo-tikok-icon-transparent-tikok-app-logo-free-png.png"
    },
    {
      name: "Telegram",
      url: "https://t.me/akalintechbot",
      icon: "https://cdn.freebiesupply.com/logos/large/2x/telegram-logo-png-transparent.png"
    },
    {
      name: "WhatsApp",
      url: "https://chat.whatsapp.com/I8gYGohgjzUGX1IHKw4s17",
      icon: "https://static.vecteezy.com/system/resources/thumbnails/023/986/631/small/whatsapp-logo-whatsapp-logo-transparent-whatsapp-icon-transparent-free-free-png.png"
    },
    {
      name: "Reddit",
      url: "https://www.reddit.com/user/akalintech/",
      icon: "https://seeklogo.com/images/R/reddit-new-2023-logo-16086DD48B-seeklogo.com.png"
    },
    {
      name: "Github",
      url: "https://github.com/akalintech",
      icon: "https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png"
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/akalintech",
      icon: "https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/@AkalinTech",
      icon: "https://www.freeiconspng.com/thumbs/youtube-logo-png/hd-youtube-logo-png-transparent-background-20.png"
    },
    {
      name: "Medium",
      url: "https://medium.com/@akalintech",
      icon: "https://miro.medium.com/v2/resize:fill:88:88/1*4eBYLmk70ln9psBY4_bL9A.jpeg"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Main container */}
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col items-center justify-center space-y-8">
          {/* Profile Image */}
          <div className="relative group">
            <img 
              src="https://avatars.githubusercontent.com/u/188956919?v=4"
              alt="Profile"
              className="rounded-full w-64 h-64 transition-all duration-300 group-hover:shadow-lg group-hover:shadow-cyan-500/50"
            />
          </div>

          {/* Name */}
          <h1 className="text-4xl md:text-6xl font-bold tracking-wider animate-fade-in">
            AKALIN TECH
          </h1>

          {/* Description */}
          <h3 className="text-xl md:text-2xl font-bold tracking-wider animate-fade-in">
            Web Sitesi , Mobil uygulama , Pentest  ve çok daha fazlasını sunuyoruz.
          </h3>

          {/* Social Links */}
          <div className="flex flex-wrap justify-center gap-6 mt-8">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="group relative"
              >
                <div className="w-12 h-12 rounded-lg bg-gray-800 p-2 transition-all duration-300 group-hover:bg-gray-700 group-hover:shadow-lg group-hover:shadow-cyan-500/30 flex items-center justify-center">
                  <img 
                    src={link.icon}
                    alt={link.name}
                    className="w-8 h-8"
                  />
                  <ExternalLink className="absolute top-0 right-0 w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Interactive Background */}
      <div 
        className="fixed inset-0 pointer-events-none z-0"
        style={{
          background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(6, 182, 212, 0.1) 0%, rgba(6, 182, 212, 0) 50%)`
        }}
      />
    </div>
  );
};

export default Portfolio;